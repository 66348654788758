/* eslint-disable no-console */
// @refresh reset
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { mimir } from '@/services/api';
import { PageViewGTM } from '@lib/gtm';
import { TimeLine } from '@/types/types';
import { getCurrentHub } from '@sentry/nextjs';
import dynamic from 'next/dynamic';
import { listingRearranger } from '@/components/Ads/Rearrangers';
import ItemListNews from '../ItemListLastNews';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const LoadingLogo = dynamic(() => import('../LoadingLogo'));
const InfiniteScrollComponent = dynamic(() => import('../ObserverInfiniteScroll'));
export const routeTypes = ['mais', 'categoria', 'tipo', 'tipoDePublicacao', 'produto', 'empresa', 'autor', 'celebridade', 'ultimas', 'id'] as const;

interface PageNews {
  posts?: TimeLine[];
  route: string;
  routeType: (typeof routeTypes)[number];
  paginacao?: string;
  rootPage?: string | string[];
  initialPage?: string | string[];
  noPagination?: boolean;
  setNoItemsFound: Dispatch<SetStateAction<boolean>>;
}

interface ApiProps {
  status: number;
  nome: string;
  data: {
    timeline: {
      itens: TimeLine[];
      paginacao?: string;
    };
  };
}

export default function PageNewsWithMimir({ posts = [], paginacao, rootPage, initialPage, routeType, route, noPagination = false, setNoItemsFound }: PageNews) {
  const endpoints = (route: string) => {
    return {
      ultimas: `${publicRuntimeConfig.NEXT_PUBLIC_API_MIMIR}/timelines/ultimas/`,
      mais: `${publicRuntimeConfig.NEXT_PUBLIC_API_MIMIR}/timelines/mais/${route}/${route === 'lidas' ? '1-dia' : '7-dias'}`,
      categoria: `${publicRuntimeConfig.NEXT_PUBLIC_API_MIMIR}/timelines/ultimas/categoria/${route}`,
      tipo: `${publicRuntimeConfig.NEXT_PUBLIC_API_MIMIR}/timelines/ultimas/tipo/${route}`,
      tipoDePublicacao: `${publicRuntimeConfig.NEXT_PUBLIC_API_MIMIR}/timelines/ultimas/tipo/artigo/${route}`,
      empresa: `${publicRuntimeConfig.NEXT_PUBLIC_API_MIMIR}/timelines/ultimas/empresa/${route}`,
      produto: `${publicRuntimeConfig.NEXT_PUBLIC_API_MIMIR}/timelines/ultimas/${route}`,
      autor: `${publicRuntimeConfig.NEXT_PUBLIC_API_MIMIR}/timelines/ultimas/autor/${route}`,
      celebridade: `${publicRuntimeConfig.NEXT_PUBLIC_API_MIMIR}/timelines/ultimas/celebridade/${route}`,
      id: `${publicRuntimeConfig.NEXT_PUBLIC_API_MIMIR}/timelines/ultimas/id/${route}`,
    };
  };

  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const pageNewsSpan = currentSpan && currentSpan.startChild({ op: 'pageNews' });

  const iniPage = initialPage ? parseInt(initialPage.toString().replace(`p`, ``), 10) : 0;

  const [atualPage, setAtualPage] = useState(iniPage || 0);

  const [isLoading, setIsLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  const [dataPosts, setDataPosts] = useState(listingRearranger<TimeLine>({ content: posts, primary: true }));

  const pageRef = useRef(iniPage && iniPage > 1 ? iniPage : 1);
  const paginationCodeRef = useRef(paginacao);
  const routeRef = useRef(route);
  const routeTypeRef = useRef(routeType);

  // Resetar estado ao mudar de categoria
  useEffect(() => {
    paginationCodeRef.current = paginacao;
    pageRef.current = iniPage && iniPage > 1 ? iniPage : 1;
    routeRef.current = route;
    routeTypeRef.current = routeType;

    setDataPosts([]);
  }, [route, routeType]);

  useEffect(() => {
    if (posts.length > 0) {
      const dataItensNew = listingRearranger<TimeLine>({ content: posts, primary: true });
      setDataPosts(dataItensNew);
    }
  }, [posts]);

  const shouldStopPagination = !paginationCodeRef && dataPosts.length > 0;

  const getMorePosts = async () => {
    setIsLoading(true);
    const isFirstPage = !paginationCodeRef.current;

    try {
      const atualRoute = await routeRef.current;
      const atualRouteType = await routeTypeRef.current;

      const endpointsAtual = await endpoints(atualRoute);

      const endpointUrl = new URL(endpointsAtual[atualRouteType]);
      if (paginationCodeRef.current) {
        endpointUrl.searchParams.delete('p');
        endpointUrl.searchParams.append('pagination', paginationCodeRef.current);
      }
      const { data } = await mimir.get<ApiProps>(endpointUrl.toString());
      const dataItensNew = await listingRearranger<TimeLine>({ content: data.data.timeline.itens, primary: isFirstPage });

      paginationCodeRef.current = data.data.timeline.paginacao && data.data.timeline.paginacao;

      if (dataPosts.length > 0) pageRef.current = pageRef.current + 1;
      setDataPosts(oldVal => [...oldVal, ...dataItensNew]);
      setNoItemsFound(isFirstPage && data.data.timeline.itens.length === 0);
      setIsLastPage(!data.data.timeline.paginacao);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const pagesArticles = Array.from(document.getElementsByClassName('pg-article'));
      let pg = parseInt(pagesArticles[0]?.getAttribute('data-page'), 10);

      if (pagesArticles[0]?.getBoundingClientRect()?.y > 0 && atualPage > 0) {
        setAtualPage(0);

        const rtPage = rootPage === '/' ? '/' : `${`/${rootPage}/`}${iniPage > 1 ? `p/${iniPage}/` : ''}`;
        if (!noPagination) window.history.pushState('', '', rtPage);
        PageViewGTM(window.location.pathname);

        return;
      }

      if (pagesArticles[0]?.getBoundingClientRect()?.y > 0) {
        return;
      }

      pagesArticles?.forEach((p, i) => {
        const next = i + 1 <= pagesArticles.length ? pagesArticles[i + 1] : undefined;
        const pNext = next ? next?.getBoundingClientRect() : undefined;
        const pAtual = p?.getBoundingClientRect();

        pg = parseInt(p?.getAttribute('data-page'), 10);

        if ((pAtual.y < 0 && pNext === undefined) || (pAtual.y < 0 && pNext.y > 0)) {
          if (pg !== atualPage) {
            let routeValue;
            if (rootPage === '/') {
              routeValue = pg === 1 ? `/ultimas/` : `/ultimas/p/${pg}/`;
            } else if (rootPage === 'podcast') {
              routeValue = pg === 1 ? `/podcast/ultimos/` : `/podcast/ultimos/p/${pg}/`;
            } else {
              routeValue = pg === 1 ? `/${rootPage}/` : `/${rootPage}/p/${pg}/`;
            }

            PageViewGTM(window.location.pathname);
            setAtualPage(pg);
            if (!noPagination) window.history.pushState('', '', routeValue);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <>
      <div id="NewsContainer">
        {dataPosts && <ItemListNews items={dataPosts} page={pageRef.current} />}
        <LoadingLogo isLoading={isLoading && !isLastPage} />
        {dataPosts && !isLoading && !isLastPage && !shouldStopPagination && <InfiniteScrollComponent fetchMore={getMorePosts} />}
      </div>
      {pageNewsSpan && pageNewsSpan.finish()}
    </>
  );
}
