import Link from 'next/link';
import Icon from '../Icon';

type RecentNewsButtonProps = {
  link: string;
};

export default function RecentNewsButton({ link }: RecentNewsButtonProps) {
  return (
    <Link href={`/${link}/`} passHref>
      <a
        className="left-0 right-0 z-[997] mx-auto my-2 flex flex-row items-center rounded-2xl bg-mglBlue400 px-4 py-2 text-center leading-none text-mglGray50 no-underline"
        title="Ver notícias mais recentes">
        <div className="flex">
          <Icon icon="mdi:refresh" width={16} height={16} />
        </div>
        <div className="ml-2">Ver notícias mais recentes</div>
      </a>
    </Link>
  );
}
