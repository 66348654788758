import axios from 'axios';
import axiosRetry from 'axios-retry';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const api = axios.create({
  baseURL: `${publicRuntimeConfig.NEXT_PUBLIC_API_BASE}/api`,
  timeout: 10000,
  responseType: 'json',
});

const site = axios.create({
  baseURL: `${publicRuntimeConfig.NEXT_PUBLIC_API_BASE}/${publicRuntimeConfig.NEXT_PUBLIC_API_VERSION}`,
  timeout: 10000,
  responseType: 'json',
});

const mimir = axios.create({
  baseURL: publicRuntimeConfig.NEXT_PUBLIC_API_MIMIR,
  timeout: 10000,
  responseType: 'json',
});

const guia = axios.create({
  baseURL: publicRuntimeConfig.NEXT_PUBLIC_API_GUIA,
  timeout: 10000,
  responseType: 'json',
});

api.interceptors.request.use(config => {
  config.url = encodeURI(config.url);
  return config;
});

site.interceptors.request.use(config => {
  config.url = encodeURI(config.url);
  return config;
});

// 408 Request Timeout,
// 500 Internal Server Error,
// 502 Bad Gateway,
// 503 Service Unavailable ,
// 504 Gateway Timeout
// Se ECONNABORTED, vem do timeOut do Axios
// Se isNetworkOrIdempotentRequestError validado pelo axiosRetry
axiosRetry(api, {
  retries: 5,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: error =>
    error.response?.status === 408 ||
    error.response?.status === 500 ||
    error.response?.status === 502 ||
    error.response?.status === 503 ||
    error.response?.status === 504 ||
    error.code === 'ECONNABORTED' ||
    axiosRetry.isNetworkOrIdempotentRequestError(error),
});

axiosRetry(site, {
  retries: 5,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: error =>
    error.response?.status === 408 ||
    error.response?.status === 500 ||
    error.response?.status === 502 ||
    error.response?.status === 503 ||
    error.response?.status === 504 ||
    error.code === 'ECONNABORTED' ||
    axiosRetry.isNetworkOrIdempotentRequestError(error),
});

axiosRetry(mimir, {
  retries: 5,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: error =>
    error.response?.status === 408 ||
    error.response?.status === 500 ||
    error.response?.status === 502 ||
    error.response?.status === 503 ||
    error.response?.status === 504 ||
    error.code === 'ECONNABORTED' ||
    axiosRetry.isNetworkOrIdempotentRequestError(error),
});

const validatesResponse = (
  dataResponseAPI,
  isCatch = false,
  isCategory = false,
  category: string | string[] = '',
  host = null,
  prevHost = null,
  pathName = null
) => {
  // Usando o CT status que são:
  // case 0: //sucesso(200)
  // case 1: //erro no servidor(500)
  // case 2: //acesso negado(403)
  // case 3: //não encontrado(404)
  // case 4: //inconsistencia(400)
  // case 5: //metodo não permitido(405)
  // case 6: //redirect(301)
  // case 7: //redirect(301)

  let data = null;
  let statusCode = 0;

  if (!isCatch && dataResponseAPI) {
    data = dataResponseAPI.data;
    statusCode = dataResponseAPI.status;
    if (data?.status == null) {
      return { data, statusCode };
    }
  }

  switch (isCatch ? dataResponseAPI.response?.data?.status : dataResponseAPI.data.status) {
    case 3:
      if (!isCategory) {
        data = {
          notFound: true,
          props: {},
        };
      } else {
        data = {
          redirect: {
            statusCode: 301,
            destination: `${publicRuntimeConfig.NEXT_PUBLIC_URL}/${category}/`,
          },
          props: {},
        };
      }
      break;
    case 8:
      return {
        data: {
          headers: isCatch ? dataResponseAPI.response.data.headers : data.headers,
        },
        statusCode: isCatch ? dataResponseAPI.response.data.data.codigo : data.data.codigo,
      };
      break;
    case 1:
    case 2:
    case 4:
    case 5:
      statusCode = isCatch ? dataResponseAPI.response.data.data.codigo : data.data.codigo;
      break;
    case 6:
    case 7:
      data = {
        redirect: {
          statusCode: isCatch ? dataResponseAPI.response.data.data.codigo : data.data.codigo,
          destination: isCatch ? dataResponseAPI.response.data.data.url : data.data.url,
        },
        props: {},
      };
      break;
    default:
      if (isCatch) throw dataResponseAPI;
      statusCode = dataResponseAPI.status;
  }

  const desiredHost = dataResponseAPI?.data?.data?.dominio || publicRuntimeConfig.NEXT_PUBLIC_HOST;

  if (host?.toString() !== desiredHost?.toString()) {
    data = {
      redirect: {
        statusCode: 301,
        destination: `https://${desiredHost}${dataResponseAPI?.data?.data?.url || pathName || ''}`,
      },
      props: {},
    };
  }

  return { data, statusCode };
};

export { api, site, validatesResponse, mimir, guia };
